import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap'
import { Input } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { formatLocation } from '../util/utils'
import { RootState, AppDispatch, createCartThunk, fetchAllUsersThunk } from '../../store'
import PartnerCustomerForm from './PartnerCustomerForm'
import { IUser } from '../../types/user'

interface NoCartProps {
  searchValue: string
  user: IUser
  setSearchValue: (value: string) => void
  fetchAllUsers: () => void
  allUsers: IUser[]
  createCart: (body: any) => void
}

const EditOrCreateCartButton = ({
  user,
  history,
  createCart,
}: {
  user: IUser
  createCart: (body: any) => void
}) => {
  const { location, licenseDetails, _id } = user || {}
  const hasLocation = !!location
  const hasLicenseDetails = licenseDetails?.licenseNumber && licenseDetails?.licensePhoto

  const handleEditUser = () => history.push(`/admin/edit/user/${_id}`)
  const handleCreateCart = () => createCart({ customer: _id })

  const getButtonText = () => {
    if (!hasLicenseDetails && !hasLocation) return 'Upload Missing License Details & Set Location'
    if (!hasLicenseDetails) return 'Upload Missing License Details'
    return 'Set Location'
  }

  return !hasLicenseDetails || !hasLocation ? (
    <Button onClick={handleEditUser} style={{ maxHeight: 35 }} variant="warning">
      {getButtonText()}
    </Button>
  ) : (
    <Button onClick={handleCreateCart} style={{ maxHeight: 35 }} disabled={!hasLicenseDetails}>
      Create Cart
    </Button>
  )
}

const NoCart = ({
  searchValue,
  user,
  setSearchValue,
  fetchAllUsers,
  allUsers,
  createCart,
}: NoCartProps) => {
  const [filteredUsers, setFilteredUsers] = useState<any[] | []>([])
  const { isPartner } = user

  const me = user
  const history = useHistory()

  useEffect(() => {
    if (!allUsers?.length) {
      fetchAllUsers()
    } else {
      setFilteredUsers(allUsers)
    }
  }, [allUsers])

  const filterUsers = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchValue(e.target.value)
    const filtered = allUsers.filter((customer) => {
      if (
        customer.firstName.toLowerCase().includes(value) ||
        customer.lastName.toLowerCase().includes(value) ||
        customer.email.toLowerCase().includes(value) ||
        customer.businessName?.toLowerCase().includes(value)
      ) {
        return customer
      }
    })

    if (e.target.value.length === 0) {
      setFilteredUsers(allUsers)
    } else {
      setFilteredUsers(filtered)
    }
  }

  if (isPartner) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Alert className="mt-3">
              You have no active carts. Enter customer details and create a cart to begin the
              checkout process.
            </Alert>
          </Col>
        </Row>
        <Row>
          <PartnerCustomerForm createCart={createCart} />
        </Row>
      </Container>
    )
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Alert className="mt-3">
            You have no active carts. Choose a user and create a cart to begin the checkout process.
          </Alert>
        </Col>
      </Row>
      <Row>
        <div className="converter-search-container">
          <Input
            className="ui action input converter-search-input"
            type="text"
            placeholder="Search for a user"
            name="serialNumber"
          >
            <input value={searchValue} onChange={filterUsers} />
          </Input>
        </div>
      </Row>
      <Row>
        <Col>
          <Link to="/admin/add/user" className="ui button dashboard-add-converter-btn orange">
            Add User
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xl="12">
          {filteredUsers.map((user) => {
            if (user.email !== me.email) {
              return (
                <Card key={user._id} className="mt-3">
                  <Card.Body>
                    <Row>
                      <Col
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div>
                          <strong>{`${user.firstName} ${user.lastName}`}</strong>
                        </div>
                        {user.businessName && <div>{user.businessName}</div>}
                        <div>{user.email}</div>
                        {user.location && <div>{`${formatLocation(user.location)}`}</div>}
                        <div>Margin: {user.margin * 100}%</div>
                      </Col>
                      <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div>
                          <EditOrCreateCartButton
                            user={user}
                            history={history}
                            createCart={createCart}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              )
            }
          })}
        </Col>
      </Row>
    </Container>
  )
}

const mapState = (state: RootState) => {
  return {
    user: state.user,
    allUsers: state.admin.allUsers,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    createCart(body: any) {
      dispatch(createCartThunk(body))
    },
    fetchAllUsers() {
      dispatch(fetchAllUsersThunk())
    },
  }
}

export default connect(mapState, mapDispatch)(NoCart)
