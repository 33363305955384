import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Image } from 'react-bootstrap'
import { ICart } from '../../types/cart'

interface FloridaFormModalProps {
  cart: ICart
  show: boolean
  onHide: () => void
  onUpload: ({ floridaForm, format }: { floridaForm: string; format: string }) => void
  setFormUpdated: (updated: boolean) => void
}

const FloridaFormModal: React.FC<FloridaFormModalProps> = ({
  cart,
  show,
  onHide,
  onUpload,
  setFormUpdated,
}) => {
  const [floridaForm, setFloridaForm] = useState<string | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [format, setFormat] = useState<string>('')

  useEffect(() => {
    if (cart.floridaForm) {
      setPreviewUrl(cart.floridaForm)
      setFloridaForm(cart.floridaForm)
    }
  }, [])

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string)
        setFloridaForm(reader.result as string)
      }
      reader.readAsDataURL(file)
      setFormUpdated(true)
      setFormat(file.name.split('.').pop() || '') // Get the file extension
    }
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (floridaForm) {
      onUpload({ floridaForm, format })
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Take or Upload a Picture of the Florida Form</Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFile">
            <Form.Label>Upload form</Form.Label>
            <Form.Control type="file" accept="image,pdf/*" onChange={handleFormChange} />
          </Form.Group>

          {previewUrl && (
            <div className="my-3 text-center">
              <div style={{ fontSize: '12px', marginRight: '16px' }}>Existing upload:</div>
              <embed src={`${previewUrl}`} style={{ height: '500px', width: '100%' }} />
            </div>
          )}

          <Button style={{ marginTop: '12px' }} variant="primary" type="submit">
            {previewUrl ? 'Next' : 'Upload'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default FloridaFormModal
