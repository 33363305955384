import React from 'react'
import { connect } from 'react-redux'
import { fetchSingleConverter, removeSingleConverter } from '../../store'
import { Link } from 'react-router-dom'
import Loading from '../loading'
import UserError from '../admin-dashboard/users/user-error'

class SelectedConverter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: 0,
      serialNumber: '',
      secondarySerialNumber: '',
      make: '',
      weight: '',
      currentPrice: 0.0,
    }
  }

  componentDidMount() {
    if (this.props.selectedConverter.serialNumber === '') {
      let urlArr = this.props.match.url.split('/')
      let serialNumber = urlArr[2]
      this.props.fetchSingleConverter(serialNumber)
    }
  }

  componentDidUpdate() {
    if (this.props.selectedConverter.id === 0) {
      let urlArr = this.props.match.url.split('/')
      let serialNumber = urlArr[2]
      this.props.fetchSingleConverter(serialNumber)
    }
  }

  componentWillUnmount() {
    const body = {
      id: 0,
      serialNumber: '',
      make: '',
      weight: '',
      currentPrice: 0.0,
    }
    this.props.removeSelectedConverter(body)
  }

  render() {
    const { error } = this.props.selectedConverter
    const { isAdmin, isPartner, user } = this.props

    const logoUrl =
      isPartner && user.partnerLogo ? user.partnerLogo : 'https://i.imgur.com/695Ibmm.png'

    const noError = !error
    window.scroll(0, 0)
    if (error) {
      return <UserError error={error} />
    } else if (this.props.selectedConverter.id === 0) {
      return <Loading />
    } else {
      return (
        <div className="site-container">
          <Link to="/dashboard" className="ui button dashboard-add-converter-btn">
            Back to Dashboard
          </Link>
          {isAdmin && (
            <div className="margin-top full-width">
              <Link
                to={`/admin/edit/converter/${this.props.selectedConverter.id}`}
                className="ui button blue dashboard-add-converter-btn"
              >
                {`Edit ${this.props.selectedConverter.serialNumber}`}
              </Link>
            </div>
          )}

          <div className="selected-info-container">
            <div className="selected-info-top-row">
              <div className="bold">{`${this.props.selectedConverter.serialNumber} ${
                this.props.selectedConverter.secondarySerialNumber
                  ? ` - ${this.props.selectedConverter.secondarySerialNumber}`
                  : ''
              }`}</div>
              <div>{`${this.props.selectedConverter.make}`}</div>
            </div>
            <div className="edit-info-body">
              <div className="selected-info-body-left bold green">
                <div className="edit-info-price">{`$${this.props.selectedConverter.currentPrice}`}</div>
              </div>
              <div className="selected-info-body-right">
                <div className="selected-img-container">
                  <img
                    className="edit-cat-img"
                    src={this.props.selectedConverter.photo}
                    src={
                      this.props.selectedConverter.make === 'Common Grade'
                        ? logoUrl
                        : this.props.selectedConverter.photo
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    allConverters: state.converter.allConverters,
    selectedConverter: state.converter.selectedConverter,
    isAdmin: state.user.isAdmin,
    isPartner: state.user.isPartner,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSingleConverter: (serialNumber) => dispatch(fetchSingleConverter(serialNumber)),
    removeSelectedConverter: (body) => dispatch(removeSingleConverter(body)),
  }
}

const connected = connect(mapStateToProps, mapDispatchToProps)(SelectedConverter)

export default connected
