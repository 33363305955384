import * as yup from 'yup'
import { Form } from 'react-bootstrap'
import React from 'react'
import { parse, format } from 'date-fns'
import { toast } from 'react-toastify'
import { BrowserPDF417Reader } from '@zxing/browser'
import { FormikProps } from 'formik'
import { AddUserFormValues } from './AddUser'

export const states = [
  { text: 'Alabama', value: 'Alabama' },
  { text: 'Alaska', value: 'Alaska' },
  { text: 'American Samoa', value: 'American Samoa' },
  { text: 'Arizona', value: 'Arizona' },
  { text: 'Arkansas', value: 'Arkansas' },
  { text: 'California', value: 'California' },
  { text: 'Colorado', value: 'Colorado' },
  { text: 'Connecticut', value: 'Connecticut' },
  { text: 'Delaware', value: 'Delaware' },
  { text: 'District Of Columbia', value: 'District Of Columbia' },
  { text: 'Federated States Of Micronesia', value: 'Federated States Of Micronesia' },
  { text: 'Florida', value: 'Florida' },
  { text: 'Georgia', value: 'Georgia' },
  { text: 'Guam', value: 'Guam' },
  { text: 'Hawaii', value: 'Hawaii' },
  { text: 'Idaho', value: 'Idaho' },
  { text: 'Illinois', value: 'Illinois' },
  { text: 'Indiana', value: 'Indiana' },
  { text: 'Iowa', value: 'Iowa' },
  { text: 'Kansas', value: 'Kansas' },
  { text: 'Kentucky', value: 'Kentucky' },
  { text: 'Louisiana', value: 'Louisiana' },
  { text: 'Maine', value: 'Maine' },
  { text: 'Marshall Islands', value: 'Marshall Islands' },
  { text: 'Maryland', value: 'Maryland' },
  { text: 'Massachusetts', value: 'Massachusetts' },
  { text: 'Michigan', value: 'Michigan' },
  { text: 'Minnesota', value: 'Minnesota' },
  { text: 'Mississippi', value: 'Mississippi' },
  { text: 'Missouri', value: 'Missouri' },
  { text: 'Montana', value: 'Montana' },
  { text: 'Nebraska', value: 'Nebraska' },
  { text: 'Nevada', value: 'Nevada' },
  { text: 'New Hampshire', value: 'New Hampshire' },
  { text: 'New Jersey', value: 'New Jersey' },
  { text: 'New Mexico', value: 'New Mexico' },
  { text: 'New York', value: 'New York' },
  { text: 'North Carolina', value: 'North Carolina' },
  { text: 'North Dakota', value: 'North Dakota' },
  { text: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
  { text: 'Ohio', value: 'Ohio' },
  { text: 'Oklahoma', value: 'Oklahoma' },
  { text: 'Oregon', value: 'Oregon' },
  { text: 'Palau', value: 'Palau' },
  { text: 'Pennsylvania', value: 'Pennsylvania' },
  { text: 'Puerto Rico', value: 'Puerto Rico' },
  { text: 'Rhode Island', value: 'Rhode Island' },
  { text: 'South Carolina', value: 'South Carolina' },
  { text: 'South Dakota', value: 'South Dakota' },
  { text: 'Tennessee', value: 'Tennessee' },
  { text: 'Texas', value: 'Texas' },
  { text: 'Utah', value: 'Utah' },
  { text: 'Vermont', value: 'Vermont' },
  { text: 'Virgin Islands', value: 'Virgin Islands' },
  { text: 'Virginia', value: 'Virginia' },
  { text: 'Washington', value: 'Washington' },
  { text: 'West Virginia', value: 'West Virginia' },
  { text: 'Wisconsin', value: 'Wisconsin' },
  { text: 'Wyoming', value: 'Wyoming' },
]

export const stateAbbreviations = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

// Define the validation schema using yup
export const editUserSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  businessName: yup.string().required('Business name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  margin: yup
    .number()
    .required('Margin is required')
    .min(1, 'Minimum at least 1')
    .max(99, 'Allowed maximum is 99'),
  location: yup.string().required('Location required'),
  permissionLevel: yup.string().required('Permission level required'),
  streetAddress: yup
    .string()
    .required('Address is required')
    .test('streetAddress', 'Sorry, you must have a physical address, not a PO Box', (value) => {
      if (!value) return false
      const results = value.match(
        /(((p[\s.]?[o\s][.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/i
      )
      return !(results && results.length)
    }),
  streetAddress2: yup
    .string()
    .nullable()
    .test('streetAddress', 'Sorry, you must have a physical address, not a PO Box', (value) => {
      if (!value) return true
      const results = value.match(
        /(((p[\s.]?[o\s][.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/i
      )
      return !(results && results.length)
    }),
  city: yup.string().required('City is required'),
  state: yup
    .string()
    .required('State is required')
    .test('state', 'Must be a valid state', (value) => {
      if (!value) return true
      return value !== '-'
    }),
  postalCode: yup.string().required('Postal code is required'),
  // fingerprint: yup
  //   .mixed()
  //   .when(['location', 'permissionLevel'], {
  //     is: (location: string, permissionLevel: string) =>
  //       location === 'florida' && !buyerRoles.includes(permissionLevel),
  //     then: yup
  //       .string()
  //       .required('Fingerprint upload is required for Florida and specific permission levels'),
  //     otherwise: yup.string().notRequired(),
  //   })
  //   .test('FILE_SIZE', 'Uploaded file is too big.', (value) => {
  //     const base64 = value?.split(',')[1] || value
  //     if (!base64 || base64.startsWith('http')) return true
  //     return atob(base64).length <= 30000000
  //   })
  //   .test('FILE_FORMAT', 'Uploaded file has unsupported format.', (value) => {
  //     if (!value || value.startsWith('http')) return true

  //     const mimeType = value.match(/^data:(.*?);base64,/)?.[1]

  //     return ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(mimeType)
  //   }),
  // License-related fields with conditional validation
  licensePhoto: yup.string().when('permissionLevel', {
    is: 'customer',
    then: yup.string().required('License photo is required'),
    otherwise: yup.string().notRequired(),
  }),
  // licenseNumber: yup.string().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup.string().required('License number is required'),
  //   otherwise: yup.string().notRequired(),
  // }),
  // licenseExpirationDate: yup.date().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup
  //     .date()
  //     .min(new Date(), 'Expiration date must be in the future')
  //     .required('Expiration date is required'),
  //   otherwise: yup.date().notRequired(),
  // }),
  // licenseIssueDate: yup.date().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup
  //     .date()
  //     .max(new Date(), 'Issue date must be in the past')
  //     .required('Issue date is required'),
  //   otherwise: yup.date().notRequired(),
  // }),
  // dob: yup.date().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup
  //     .date()
  //     .max(new Date(), 'Date of Birth must be in the past')
  //     .test('age', 'You must be at least 18 years old', function (value) {
  //       if (!value) return false
  //       return process.env.NODE_ENV === 'production'
  //         ? differenceInYears(new Date(), new Date(value)) >= 18
  //         : true
  //     })
  //     .required('Date of Birth is required'),
  //   otherwise: yup.date().notRequired(),
  // }),
  // licenseState: yup.string().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup.string().required('License state is required'),
  //   otherwise: yup.string().notRequired(),
  // }),
  // heightFeet: yup.string().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup.string().required('Height (feet) is required'),
  //   otherwise: yup.string().notRequired(),
  // }),
  // heightInches: yup.string().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup.string().required('Height (inches) is required'),
  //   otherwise: yup.string().notRequired(),
  // }),
  // eyeColor: yup.string().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup.string().required('Eye color is required'),
  //   otherwise: yup.string().notRequired(),
  // }),
  // gender: yup.string().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup.string().required('Gender is required'),
  //   otherwise: yup.string().notRequired(),
  // }),
})

export const passwordSchema = yup.object({
  password: yup
    .string()
    .required('Please enter a password')
    .matches(
      /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}/,
      'Password must contain at least 8 characters, one uppercase and one number '
    ),
})

export const newUserSchema = passwordSchema.concat(editUserSchema)

export interface EditUserBodyProps {
  firstName?: string
  lastName?: string
  email?: string
  margin?: number
  location?: string
  isBuyer?: boolean
  isAdmin?: boolean
  isGuest?: boolean
  isCustomer?: boolean
  isPartner?: boolean
  streetAddress?: string
  streetAddress2?: string
  city?: string
  state?: string
  postalCode?: string
  userId?: string
  userPermission?: boolean
  fingerprint?: string
  floridaForm?: string
  password?: string
  licenseDetails?: {
    licenseNumber?: string
    licenseExpirationDate?: string
    licenseIssueDate?: string
    licenseState?: string
    gender?: string
    dob?: string
    height?: string
    eyeColor?: string
    licensePhoto?: string
  }
}

export type AddUserBodyProps = EditUserBodyProps

export const HeightForm = ({ formik }) => {
  const feetOptions = [...Array(8).keys()].slice(1) // Feet options: 1 to 7
  const inchOptions = [...Array(12).keys()] // Inch options: 0 to 11

  return (
    <Form.Group>
      <div style={{ display: 'flex', gap: '10px' }}>
        <Form.Select
          name="heightFeet"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.heightFeet}
          isValid={formik.touched.heightFeet && !formik.errors.heightFeet}
        >
          <option value="">Feet</option>
          {feetOptions.map((ft) => (
            <option key={ft} value={ft}>
              {ft} ft
            </option>
          ))}
        </Form.Select>

        <Form.Select
          name="heightInches"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.heightInches}
          isValid={formik.touched.heightInches && !formik.errors.heightInches}
        >
          <option value="">Inches</option>
          {inchOptions.map((inch) => (
            <option key={inch} value={inch}>
              {inch} in
            </option>
          ))}
        </Form.Select>
      </div>
      {(formik.touched.heightFeet || formik.touched.heightInches) && (
        <Form.Control.Feedback type="invalid">
          {formik.errors.heightFeet || formik.errors.heightInches}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

export const formikLicenseTouched = (formik: any) =>
  (formik.touched.licenseNumber &&
    formik.values.licenseNumber !== formik.initialValues.licenseNumber) ||
  (formik.touched.licenseIssueDate &&
    formik.values.licenseIssueDate !== formik.initialValues.licenseIssueDate) ||
  (formik.touched.licenseExpirationDate &&
    formik.values.licenseExpirationDate !== formik.initialValues.licenseExpirationDate) ||
  (formik.touched.licenseState &&
    formik.values.licenseState !== formik.initialValues.licenseState) ||
  (formik.touched.eyeColor && formik.values.eyeColor !== formik.initialValues.eyeColor) ||
  (formik.touched.gender && formik.values.gender !== formik.initialValues.gender) ||
  (formik.touched.dob && formik.values.dob !== formik.initialValues.dob) ||
  (formik.touched.heightFeet && formik.values.heightFeet !== formik.initialValues.heightFeet) ||
  (formik.touched.heightInches && formik.values.heightInches !== formik.initialValues.heightInches)

export const formikLicenseErrors = (formik: any) =>
  formik.errors.licenseNumber ||
  formik.errors.licenseIssueDate ||
  formik.errors.licenseExpirationDate ||
  formik.errors.licenseState ||
  formik.errors.eyeColor ||
  formik.errors.gender ||
  formik.errors.dob ||
  formik.errors.heightFeet ||
  formik.errors.heightInches

// Helper function to parse the barcode data
export const parseLicenseData = (decodedData: string): Record<string, string> => {
  const licenseInfo: Record<string, string> = {}

  // Example: Parsing a standard AAMVA-compliant license barcode
  // Adjust parsing logic based on the format of your data
  const lines = decodedData.split('\n')
  lines.forEach((line) => {
    if (line.startsWith('DAA')) licenseInfo.fullName = line.substring(3).trim()
    if (line.startsWith('DAB')) licenseInfo.lastName = line.substring(3).trim()
    if (line.startsWith('DAC')) licenseInfo.firstName = line.substring(3).trim()
    if (line.startsWith('DBA')) licenseInfo.expirationDate = line.substring(3).trim()
    if (line.startsWith('DBB')) licenseInfo.dateOfBirth = line.substring(3).trim()
    if (line.startsWith('DBC')) licenseInfo.gender = line.substring(3).trim()
    if (line.startsWith('DAG')) licenseInfo.address = line.substring(3).trim()
    if (line.startsWith('DAJ')) licenseInfo.state = line.substring(3).trim()
    if (line.startsWith('DAQ')) licenseInfo.idNumber = line.substring(3).trim()
    if (line.startsWith('DBD')) licenseInfo.issueDate = line.substring(3).trim()
    if (line.startsWith('DAU')) licenseInfo.height = line.substring(3).trim()
    if (line.startsWith('DAY')) licenseInfo.eyeColor = line.substring(3).trim()
  })

  return licenseInfo
}

export const genderMapping = {
  '1': 'Male',
  '2': 'Female',
  '9': 'Not Specified',
}

export const handleExtractLicenseInfo = async (
  licensePhotoBackFile: File | undefined,
  formik: FormikProps<AddUserFormValues>
) => {
  if (!licensePhotoBackFile) {
    toast.error(
      'No license photo back file available. Please upload a file to extract license info.',
      { autoClose: 15000 }
    )
    return
  }

  try {
    // Create a Blob URL from the file
    const fileURL = URL.createObjectURL(licensePhotoBackFile)

    // Create an Image element
    const img = new Image()
    img.src = fileURL

    // Wait for the image to load
    await new Promise<void>((resolve, reject) => {
      img.onload = () => resolve()
      img.onerror = (err) => reject(err)
    })

    const codeReader = new BrowserPDF417Reader()

    // Decode from the loaded image
    const result = await codeReader.decodeFromImageElement(img)

    // Parse the extracted data
    const parsedData = parseLicenseData(result.getText())

    // Map gender and state fields
    const genderCode = parsedData.gender
    const stateCode = parsedData.state?.substring(0, 2) // Extract first two digits of the state code

    const mappedGender = genderMapping[genderCode as keyof typeof genderMapping] || 'Unknown'
    const mappedState =
      stateAbbreviations[stateCode as keyof typeof stateAbbreviations] || 'Unknown'

    const heightFeet = parsedData.height.substring(0, 1)
    const heightInches = parseInt(parsedData.height.substring(1), 10)

    const transformDate = (date: string): string => {
      if (date && date.length === 8) {
        const parsedDate = parse(date, 'MMddyyyy', new Date())
        return format(parsedDate, 'yyyy-MM-dd')
      }
      return '' // Return empty string if the date is invalid
    }

    const dateOfBirth = transformDate(parsedData.dateOfBirth)
    const expirationDate = transformDate(parsedData.expirationDate)
    const issueDate = transformDate(parsedData.issueDate)

    // Update Formik fields with the parsed data and mapped values
    formik.setFieldValue('licenseNumber', parsedData.idNumber)
    formik.setFieldValue('licenseState', mappedState)
    formik.setFieldValue('heightFeet', heightFeet)
    formik.setFieldValue('heightInches', heightInches)
    formik.setFieldValue('eyeColor', parsedData.eyeColor)
    formik.setFieldValue('gender', mappedGender)
    formik.setFieldValue('licenseIssueDate', issueDate)
    formik.setFieldValue('licenseExpirationDate', expirationDate)
    formik.setFieldValue('dob', dateOfBirth)

    // Revoke the Blob URL to free memory
    URL.revokeObjectURL(fileURL)
  } catch (error) {
    toast.error(
      'Error extracting license info. Please ensure picture is horizontal, high quality, and only encompasses license back.',
      { autoClose: 15000 }
    )
    console.error('Error extracting license info:', error)
  }
}

export const handleFileUpload = (
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  event: React.ChangeEvent<HTMLInputElement>,
  type: 'licensePhoto' | 'fingerprint' | 'licensePhotoBack' | 'businessLicensePhoto' | 'partnerLogo'
) => {
  const file = event.target.files?.[0]
  if (file) {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (type === 'licensePhoto') {
        setFieldValue('licensePhoto', reader.result as string)
      } else if (type === 'fingerprint') {
        setFieldValue('fingerprint', reader.result as string)
      } else if (type === 'licensePhotoBack') {
        setFieldValue('licensePhotoBack', reader.result as string)
        setFieldValue('licensePhotoBackFile', file)
      } else if (type === 'businessLicensePhoto') {
        setFieldValue('businessLicensePhoto', reader.result as string)
      } else if (type === 'partnerLogo') {
        setFieldValue('partnerLogo', reader.result as string)
      }
    }
    reader.readAsDataURL(file)
  }
}

export function extractId(url: string) {
  // /admin/edit-user-2
  const urlArr = url.split('/')
  const pathArr = urlArr[urlArr.length - 1].split('-')
  const id = pathArr[pathArr.length - 1]
  return id
}

export function validateEmail(email: string) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function convertToInteger(margin: string | number) {
  return (Number(margin) * 100).toFixed(0)
}

export function validateMargin(margin: string) {
  const marginNumber = Number(margin)
  if (marginNumber && marginNumber >= 1 && marginNumber < 99.49) {
    return true
  } else {
    return false
  }
}

export function validateName(name: string) {
  if (name.length === 0 || name.length > 20) {
    return false
  } else {
    return true
  }
}

export function capitalizeFirstLetter(name: string) {
  return name.charAt(0).toUpperCase()
}

export function validatePassword(password: string) {
  const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})))')

  const test = mediumRegex.test(password)
  return test
}
