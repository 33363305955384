import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Image } from 'react-bootstrap'
import { ICart } from '../../types/cart'

interface PictureModalProps {
  cart: ICart
  show: boolean
  onHide: () => void
  onUpload: ({ buyPicture, format }: { buyPicture: string; format: string }) => void
  setPictureUpdated: (updated: boolean) => void
}

const PictureModal: React.FC<PictureModalProps> = ({
  cart,
  show,
  onHide,
  onUpload,
  setPictureUpdated,
}) => {
  const [picture, setPicture] = useState<string | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [format, setFormat] = useState<string>('')

  useEffect(() => {
    if (cart.buyPicture) {
      setPreviewUrl(cart.buyPicture)
      setPicture(cart.buyPicture)
    }
  }, [])

  const handlePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string)
        setPicture(reader.result as string)
      }
      reader.readAsDataURL(file)
      setPictureUpdated(true)
      setFormat(file.name.split('.').pop() || '') // Get the file extension
    }
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (picture) {
      onUpload({ buyPicture: picture, format: 'jpg' })
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Take or Upload a Picture of the Buy</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFile">
            <Form.Label>Choose a picture</Form.Label>
            <Form.Control type="file" accept="image*" onChange={handlePictureChange} />
          </Form.Group>
          {previewUrl && (
            <div className="my-3 text-center">
              <div style={{ fontSize: '12px', marginRight: '16px' }}>Existing photo:</div>
              <Image src={previewUrl} alt="Image preview" thumbnail width={300} />
            </div>
          )}
          <Button style={{ marginTop: '12px' }} variant="primary" type="submit" disabled={!picture}>
            {previewUrl ? 'Next' : 'Upload'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default PictureModal
