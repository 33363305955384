import { apiCaller } from '../utils/apiCaller'
import history from '../history'
import { toast } from 'react-toastify'
import { fetchCart } from './cart'
import { me } from './user'
import { isProd } from '../utils/env'

/**
 * ACTION TYPES
 */

const GET_INVOICE_PDF = 'GET_INVOICE_PDF'
const REMOVE_INVOICE_PDF = 'REMOVE_INVOICE_PDF'
const SET_LOADING = 'SET_LOADING'
const GET_ALL_INVOICES = 'GET_ALL_INVOICES'
const SET_FOCUSED_INVOICE = 'SET_FOCUSED_INVOICE'
const SET_INVOICE_PDF_ERROR = 'SET_INVOICE_PDF_ERROR'
const GET_PROFITABILITY_DATA = 'GET_PROFITABILITY_DATA'
const CLEAR_PROFITABILITY_DATA = 'CLEAR_PROFITABILITY_DATA'
const LEADS_ONLINE_SUBMISSION_ERROR = 'LEADS_ONLINE_SUBMISSION_ERROR'
const SET_LEADS_ONLINE_SUBMISSION_SUCCESS = 'SET_LEADS_ONLINE_SUBMISSION_SUCCESS'
const SET_LEADS_ONLINE_STATUS_LOADING = 'SET_LEADS_ONLINE_STATUS_LOADING'

/**
 * INITIAL STATE
 */
const initialState = {
  invoice: {},
  invoiceLoading: false,
  allInvoices: [],
  focusedInvoice: {},
  error: '',
  leadsOnlineError: '',
  leadsOnlineSuccess: false,
  leadsOnlineStatusLoading: false,
  profitabilityData: [],
}

/**
 * ACTION CREATORS
 */

const getInvoice = (invoice) => ({
  type: GET_INVOICE_PDF,
  invoice,
})

const setInvoiceLoading = (loading) => ({
  type: SET_LOADING,
  loading,
})

const getAllInvoices = (invoices) => ({
  type: GET_ALL_INVOICES,
  invoices,
})

const setFocusedInvoice = (invoice) => ({
  type: SET_FOCUSED_INVOICE,
  invoice,
})

const setInvoicePdfError = (error) => ({
  type: SET_INVOICE_PDF_ERROR,
  error,
})

const getProfitabilityData = (data) => ({
  type: GET_PROFITABILITY_DATA,
  data,
})

export const clearProfitabilityData = () => ({
  type: CLEAR_PROFITABILITY_DATA,
})

export const leadsOnlineSubmissionError = (error) => ({
  type: LEADS_ONLINE_SUBMISSION_ERROR,
  error,
})

export const setLeadsOnlineSubmissionSuccess = (success) => ({
  type: SET_LEADS_ONLINE_SUBMISSION_SUCCESS,
  success,
})

export const setLeadsOnlineStatusLoading = (loading) => ({
  type: SET_LEADS_ONLINE_STATUS_LOADING,
  loading,
})
/**
 * THUNK CREATORS
 */

export const generateExternalInvoiceThunk = (body) => async (dispatch) => {
  try {
    let response = await apiCaller(`api/invoices/generate-external`, 'post', body)
    console.log('external invoice generated ')
  } catch (err) {
    dispatch(setInvoicePdfError(err.response.data))
    dispatch(getInvoice({ error: err }))
  }
}

export const createInvoiceThunk = (body) => async (dispatch) => {
  try {
    dispatch(setInvoiceLoading(true))
    let response = await apiCaller(`api/invoices/generate-internal`, 'post', body)

    dispatch(fetchCart())

    if (response.data.invoice && response.data.cart) {
      dispatch(setInvoiceLoading(false))
      history.push(`/invoices/${response.data.invoice._id}`)
      toast.success('Invoice sent!')

      if (body.customerLocation === 'florida' && !isProd) {
        dispatch(
          sendToLeadsOnlineThunk({
            invoice: response.data.invoice,
            cart: response.data.cart,
            customerLocation: body.customerLocation,
          })
        )
      }
    }

    dispatch(getInvoice(response.data.invoice))
    dispatch(fetchAllInvoicesSingleUser())
    dispatch(me())
  } catch (err) {
    console.log('err:', err)
    dispatch(getInvoice({ error: err }))
  }
}

export const sendToLeadsOnlineThunk = (body) => async (dispatch) => {
  try {
    dispatch(setLeadsOnlineStatusLoading(true))
    let response = await apiCaller(`api/external/leadsOnline`, 'post', body)
    toast.success('Leads Online submission successful')
    dispatch(setLeadsOnlineStatusLoading(false))
    dispatch(setLeadsOnlineSubmissionSuccess(true))
  } catch (err) {
    console.log('err:', err)
    dispatch(leadsOnlineSubmissionError(err.response.data))
    dispatch(setLeadsOnlineStatusLoading(false))
  }
}

export const fetchAllInvoices = (body) => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/invoices/all`, 'post', body)
    const { invoices } = data
    dispatch(getAllInvoices(invoices))
  } catch (err) {
    dispatch(getInvoice({ error: err }))
  }
}

export const fetchBuyerProfitabilityData = (body) => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/invoices/profitability`, 'post', body)
    const { allItems } = data
    dispatch(getProfitabilityData(allItems))
  } catch (err) {
    dispatch(getInvoice({ error: err }))
  }
}

export const fetchAllInvoicesSingleUser = () => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/invoices/me`)
    const { invoices } = data
    dispatch(getAllInvoices(invoices))
  } catch (err) {
    dispatch(getInvoice({ error: err }))
  }
}

export const fetchSingleInvoice = (invoiceId) => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/invoices/${invoiceId}`)
    const { invoice } = data
    dispatch(setFocusedInvoice(invoice))
  } catch (err) {
    dispatch(setInvoicePdfError({ error: err.response }))
  }
}

export const removeInvoiceThunk = () => {
  return
}

export const removePdfThunk = () => {
  return
}

/**
 * REDUCER
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INVOICE_PDF:
      return { ...state, invoice: action.invoice }
    case SET_LOADING:
      return { ...state, invoiceLoading: action.loading }
    case GET_ALL_INVOICES:
      return { ...state, allInvoices: action.invoices }
    case REMOVE_INVOICE_PDF:
      return { ...state, invoice: action.invoice }
    case SET_FOCUSED_INVOICE:
      return { ...state, focusedInvoice: action.invoice }
    case SET_INVOICE_PDF_ERROR:
      return { ...state, error: action.error }
    case GET_PROFITABILITY_DATA:
      return { ...state, profitabilityData: action.data }
    case CLEAR_PROFITABILITY_DATA:
      return { ...state, profitabilityData: [] }
    case SET_LEADS_ONLINE_STATUS_LOADING:
      return { ...state, leadsOnlineStatusLoading: action.loading }
    case SET_LEADS_ONLINE_SUBMISSION_SUCCESS:
      return { ...state, leadsOnlineSuccess: action.success }
    case LEADS_ONLINE_SUBMISSION_ERROR:
      return { ...state, leadsOnlineError: action.error }
    default:
      return state
  }
}
