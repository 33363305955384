import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { blueLogo } from '../style/colors'
import { NavLink } from './Navigation.styled'
import { useHistory } from 'react-router-dom'
import { logout, removePdfThunk, removeSelectedUser, fetchControls } from '../store'

const Navigation = ({
  userId,
  handleLogout,
  isAdmin,
  isLoggedIn,
  isBuyer,
  isPartner,
  user,
  controls,
  fetchAllControls,
}) => {
  const { isTallyAppLive, isHedgingLive } = controls
  const { canSeeInvoiceData } = user
  const history = useHistory()

  const [logoUrl, setLogoUrl] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (isLoggedIn && isAdmin && (!isTallyAppLive || !isHedgingLive)) {
      fetchAllControls()
    }

    if (user && isPartner && user.partnerLogo) {
      setLogoUrl(user.partnerLogo)
      setLoading(false)
    } else if (user._id && !isPartner) {
      setLogoUrl('https://i.imgur.com/695Ibmm.png')
    } else if (user._id && isPartner && !user.partnerLogo) {
      setLogoUrl('')
    }
    setLoading(false)
  }, [user])

  if (!isLoggedIn) {
    return (
      <Navbar expand="lg" style={{ background: blueLogo }}>
        <Container fluid>
          <Navbar.Brand href="/dashboard">
            <img
              src={logoUrl}
              width="auto"
              height="30"
              className="d-inline-block align-top no-print"
            />
          </Navbar.Brand>
          <Nav className="justify-content-end">
            <NavLink href="/login">Login</NavLink>
          </Nav>
        </Container>
      </Navbar>
    )
  }
  return (
    <Navbar expand="lg" style={{ background: blueLogo }}>
      <Container fluid>
        <Navbar.Brand onClick={() => history.push(`/dashboard`)}>
          {loading ? (
            <div />
          ) : (
            <img
              src={logoUrl}
              width="auto"
              height="30"
              className="d-inline-block align-top no-print"
            />
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="me-auto justify-content-end"
          style={{ backgroundColor: 'white !important' }}
        >
          <Nav>
            <NavLink onClick={() => history.push(`/dashboard`)}>Home</NavLink>
            <NavDropdown title="Account" id="basic-nav-dropdown">
              {Boolean(!user.isCustomer) && (
                <>
                  <NavDropdown.Item onClick={() => history.push(`/account/${userId}`)}>
                    Edit
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                </>
              )}
              {Boolean(user && user.invoices && user.invoices.length) && (
                <>
                  <NavDropdown.Item onClick={() => history.push('/invoices')}>
                    My Invoices
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                </>
              )}

              <NavDropdown.Item onClick={() => handleLogout()}>Logout</NavDropdown.Item>
            </NavDropdown>
            {isAdmin || isBuyer ? (
              //These items both admins and buyers can see
              <NavDropdown title="Admin" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => history.push('/admin/create-price-list')}>
                  Create Price List
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push('/admin/view/users')}>
                  View Users
                </NavDropdown.Item>
                {isAdmin && (
                  //These items only admins can see
                  <>
                    <NavDropdown.Item onClick={() => history.push('/admin/add/converter')}>
                      Add Converter
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => history.push('/admin/view/users')}>
                      View Users
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => history.push('/admin/add/user')}>
                      Add User
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <>
                      <NavDropdown.Item onClick={() => history.push('/admin/view/businesses')}>
                        View Businesses
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => history.push('/admin/add/business')}>
                        Add Business
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                    </>
                    <NavDropdown.Item onClick={() => history.push('/admin/view/analytics')}>
                      App Analytics
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    {/* <NavDropdown.Item onClick={() => history.push('/admin/controls')}>
                      Price Controls
                    </NavDropdown.Item>
                    <NavDropdown.Divider /> */}
                    <NavDropdown.Item onClick={() => history.push('/admin/view/metals-data')}>
                      Historical Metals Data
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => history.push('/admin/killed-converters')}>
                      Killed Converters
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                  </>
                )}
                {(isAdmin || canSeeInvoiceData) && (
                  //These items only admins or select users can see
                  <>
                    <NavDropdown.Item onClick={() => history.push('/admin/view/invoices')}>
                      All Invoice Data
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => history.push('/admin/view/profitability')}>
                      Buyer Profitability Data
                    </NavDropdown.Item>
                  </>
                )}
              </NavDropdown>
            ) : null}
            {isAdmin || isBuyer || isPartner ? (
              <NavLink onClick={() => history.push('/admin/cart')}>Cart</NavLink>
            ) : null}
            <NavLink onClick={() => history.push('/hedging')}>Hedging</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

const mapState = (state) => {
  return {
    isLoggedIn: !!state.user.id || !!state.user._id,
    userId: state.user._id,
    user: state.user,
    isAdmin: state.user.isAdmin,
    isBuyer: state.user.isBuyer,
    isPartner: state.user.isPartner,
    controls: state.admin.controls,
    userLoading: state.user.loading,
  }
}

const mapDispatch = (dispatch) => {
  return {
    handleLogout() {
      dispatch(logout())
    },
    fetchAllControls() {
      dispatch(fetchControls())
    },
    removePDF: () => dispatch(removePdfThunk()),
    removeSelectedUser: (empty) => dispatch(removeSelectedUser(empty)),
  }
}

export default connect(mapState, mapDispatch)(Navigation)
