import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Row, Col } from 'react-bootstrap'
import { convertToInteger } from './util'

import { formatLocation } from '../../util/utils'
import { IUser } from 'client/types/user'

interface IndividualUserProps {
  user: IUser
}

const IndividualUser = ({ user }: IndividualUserProps) => {
  const margin = convertToInteger(user.margin)

  const renderStatus = (status: string, color: string) => {
    return (
      <div
        style={{
          color: 'white',
          padding: 2,
          borderRadius: 5,
          background: color,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold',
          marginBottom: 10,
          width: `75%`,
        }}
      >
        {status}
      </div>
    )
  }

  return (
    <>
      <Card.Body>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <Col xs="6" sm="4">
            {!user.killed && user.isAdmin && renderStatus('Admin', 'rgb(7, 29, 57)')}
            {!user.killed && user.isBuyer && renderStatus('Buyer', '#f2711c')}
            {!user.killed && user.isGuest && renderStatus('Guest', '#e0e1e2')}
            {!user.killed && user.isCustomer && renderStatus('Customer', '#0b5ed7')}
            {!user.killed && user.isPartner && renderStatus('Partner', '#fbbd08')}
            {user.killed && renderStatus('Deleted', '#db2828')}
            <div>{`${user.firstName} ${user.lastName}`}</div>
            {user.businessName && <div>{user.businessName}</div>}
            <div>{user.email}</div>
            <div>{formatLocation(user.location)}</div>
            <div>Margin: {margin}%</div>
            <div>{user.license ? 'Has License' : 'No License'}</div>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                <Link to={`/admin/activity/${user._id}`} className="ui button edit-user-btn green">
                  View Activity
                </Link>
              </Col>
              <Col style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                <Link to={`/admin/edit/user/${user._id}`} className="ui button edit-user-btn blue">
                  Edit
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
      <hr />
    </>
  )
}

export default IndividualUser
