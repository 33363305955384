import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ScaleLoader, BarLoader } from 'react-spinners'
import { IInvoice } from '../types/invoice'
import { IUser } from '../types/user'
import { Container, Row, Col, Card, Alert } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchAllInvoices, fetchSingleInvoice, RootState, AppDispatch } from '../store'
import moment from 'moment'
import { isProd } from '../utils/env'

const renderLeadsOnlineStatusAlert = (
  leadsOnlineStatusLoading: boolean,
  leadsOnlineError: string,
  invoice: IInvoice,
  leadsOnlineSuccess: boolean
) => {
  if (leadsOnlineStatusLoading) {
    return (
      <Alert key="info" variant="info">
        <Row className="align-items-center">
          <Col xs="auto">Checking LeadsOnline Status...</Col>
          <Col xs="auto">
            <BarLoader color="#055160" width={200} />
          </Col>
        </Row>
      </Alert>
    )
  }

  if (leadsOnlineError) {
    return (
      <Alert key="danger" variant="danger">
        There was an error sending to Leads Online. Please contact an administrator with invoice
        details.
      </Alert>
    )
  }

  if (invoice.sentToLeadsOnline || leadsOnlineSuccess) {
    return (
      <Alert key="success" variant="success">
        This transaction has been sent to Leads Online.
      </Alert>
    )
  }

  return (
    <Alert key="warning" variant="warning">
      This transaction has not been sent to Leads Online. Please contact an administrator with
      invoice details.
    </Alert>
  )
}

interface SelectedInvoiceProps {
  invoice: IInvoice
  refetchSingleInvoice: (invoiceId: string) => void
  user: IUser
  invoiceError: string
  leadsOnlineError: string
  leadsOnlineStatusLoading: boolean
  leadsOnlineSuccess: boolean
}

const SelectedInvoice = ({
  invoice,
  refetchSingleInvoice,
  user,
  invoiceError,
  leadsOnlineError,
  leadsOnlineStatusLoading,
  leadsOnlineSuccess,
}: SelectedInvoiceProps) => {
  const [loading, setLoading] = useState(true)
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    refetchSingleInvoice(id)
  }, [])

  useEffect(() => {
    if (invoice._id || invoiceError) {
      setLoading(false)
    }
  }, [invoice])

  return (
    <Container fluid>
      {loading ? (
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
          </Col>
        </Row>
      ) : (
        <div>
          {invoice._id ? (
            <div>
              <Row className="mt-3">
                {!isProd &&
                  renderLeadsOnlineStatusAlert(
                    leadsOnlineStatusLoading,
                    leadsOnlineError,
                    invoice,
                    leadsOnlineSuccess
                  )}
                {invoice && (
                  <>
                    <Col sm="12" md="6">
                      <embed src={`${invoice.invoiceUrl}`} width="100%" height="750px" />
                    </Col>
                    <Col sm="12" md="6">
                      <Card>
                        <Card.Header>Summary</Card.Header>
                        <Card.Body>
                          <div>
                            <strong>Customer</strong>
                          </div>
                          <div>{`${invoice.customer.firstName} ${invoice.customer.lastName}`}</div>
                          {invoice.customer.businessName && (
                            <div>{invoice.customer.businessName}</div>
                          )}
                          <div>{invoice.customer.email}</div>
                          <hr />
                          <div>
                            <strong>Buyer</strong>
                          </div>
                          <div>{`${invoice.createdBy.firstName} ${invoice.createdBy.lastName}`}</div>
                          <div>{invoice.createdBy.email}</div>
                          <hr />
                          <div>
                            <strong>Total Pieces:</strong>
                            {` ${invoice.pieces}`}
                          </div>
                          <div>
                            <strong>Credit:</strong>{' '}
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            }).format(invoice.credit)}
                          </div>
                          <div>
                            <strong>Cart Total:</strong>{' '}
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            }).format(invoice.cost)}
                          </div>
                          <div>
                            {' '}
                            <strong>Payment Method:</strong>{' '}
                            {invoice.payWithCheck ? 'Paid With Check' : 'Paid In Cash'}
                          </div>
                          <div>
                            {' '}
                            <strong>Invoice Date:</strong>{' '}
                            {moment(invoice.dateAdded).format('llll')}
                          </div>
                        </Card.Body>
                        <hr />
                        {user.isAdmin && (
                          <Row>
                            <Col>
                              <Alert>Only Admins can see the piece by piece breakdown</Alert>
                            </Col>
                          </Row>
                        )}
                        {invoice && user.isAdmin && (
                          <Card.Body>
                            {invoice.items.map((item) => {
                              return (
                                <div key={item._id + item.sizeModifier}>
                                  <Row>
                                    <Col>{item.serialNumber}</Col>
                                    <Col>{item.quantity}</Col>
                                    <Col>
                                      {' '}
                                      {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                      }).format(item.price)}
                                    </Col>
                                    <Col>
                                      {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                      }).format(item.price * item.quantity)}
                                    </Col>
                                  </Row>
                                  <hr />
                                </div>
                              )
                            })}
                          </Card.Body>
                        )}
                        {invoice && !user.isAdmin && (
                          <Card.Body>
                            {invoice.labels.map((item) => {
                              return (
                                <div key={item.cost + item.label + item.quantity}>
                                  <Row>
                                    <Col>{item.label}</Col>
                                    <Col>{item.quantity}</Col>
                                    <Col>
                                      {' '}
                                      {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                      }).format(item.cost)}
                                    </Col>
                                  </Row>
                                  <hr />
                                </div>
                              )
                            })}
                          </Card.Body>
                        )}
                        <Card.Body>
                          <div>
                            <strong>Signature:</strong>
                          </div>
                          <div className="mt-3">
                            <img src={`${invoice.signatureUrl}`} height="100px" width="auto" />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col className="mt-3">
                      <Card>
                        <Card.Header>Documents</Card.Header>
                        <Card.Body>
                          <Row className="d-flex flex-row">
                            {invoice.cart?.buyPicture && (
                              <Col>
                                <div>
                                  <strong>Picture of the buy:</strong>
                                </div>
                                <div className="mt-3">
                                  <img
                                    src={`${invoice.cart?.buyPicture}`}
                                    height="500px"
                                    width="auto"
                                  />
                                </div>
                              </Col>
                            )}
                            {invoice.cart?.floridaForm && (
                              <Col>
                                <div>
                                  <strong>Florida form:</strong>
                                </div>
                                <div>
                                  <embed
                                    src={`${invoice.cart?.floridaForm}`}
                                    height="500px"
                                    width="auto"
                                  />
                                </div>
                              </Col>
                            )}
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                )}
              </Row>
            </div>
          ) : (
            <div>No Invoice Found</div>
          )}
        </div>
      )}
    </Container>
  )
}

const mapState = (state: RootState) => {
  return {
    user: state.user,
    invoice: state.invoices.focusedInvoice,
    invoiceError: state.invoices.error,
    leadsOnlineError: state.invoices.leadsOnlineError,
    leadsOnlineStatusLoading: state.invoices.leadsOnlineStatusLoading,
    leadsOnlineSuccess: state.invoices.leadsOnlineSuccess,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    fetchAllInvoices() {
      dispatch(fetchAllInvoices())
    },
    refetchSingleInvoice(invoiceId: string) {
      dispatch(fetchSingleInvoice(invoiceId))
    },
  }
}

export default connect(mapState, mapDispatch)(SelectedInvoice)
