import { apiCaller } from '../utils/apiCaller'
import { toast } from 'react-toastify'

/**
 * ACTION TYPES
 */
const GET_CART = 'GET_CART'
const SET_SEARCH = 'SET_SEARCH'
const SET_MAKE = 'SET_MAKE'

/**
 * INITIAL STATE
 */

const initialState = {
  data: null,
  search: '',
  make: 'all converters',
}

/**
 * ACTION CREATORS
 */
const getCart = (cart) => ({
  type: GET_CART,
  cart,
})

export const setSearch = (search) => ({
  type: SET_SEARCH,
  search,
})

export const setMake = (make) => ({
  type: SET_MAKE,
  make,
})

/**
 * THUNK CREATORS
 */
export const fetchCart = () => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/cart`)
    let { cart } = data
    dispatch(getCart(cart))
  } catch (err) {
    console.error(err)
  }
}

export const createCartThunk = (body) => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/cart`, 'post', body)
    let { cart } = data
    dispatch(getCart(cart))
  } catch (err) {
    toast.error(`There was an error creating the cart: ${err.response.data.error}`, {
      autoClose: 5000,
    })
    console.error(err)
  }
}

export const addToCartThunk = (body) => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/cart/add`, 'put', body)
    let { cart, converter } = data
    toast.success(`Success adding ${converter.serialNumber} to cart`)

    dispatch(getCart(cart))
  } catch (err) {
    toast.error(`There was an error adding ${body.converter.serialNumber} to cart`)
    console.error(err)
  }
}

const attachKey = {
  buyPicture: 'picture',
  floridaForm: 'Florida form',
}

export const attachToCartThunk = (body) => async (dispatch) => {
  try {
    const { type } = body

    let { data } = await apiCaller(`api/cart/attach`, 'put', body)
    let { cart } = data

    toast.success(`Success attaching ${attachKey[type]} to cart.`)
    dispatch(getCart(cart))
  } catch (err) {
    toast.error(`There was an error attaching form to ${body.converter.serialNumber}`)
    console.error(err)
  }
}

export const subtractCart = (body) => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/cart/subtract`, 'put', body)
    let { cart, converter } = data
    toast.success(`Success removing 1 - ${converter.serialNumber} to cart`)
    dispatch(getCart(cart))
  } catch (err) {
    toast.error(`There was an error subtracting ${body.converter.serialNumber} to cart`)
    console.error(err)
  }
}

export const editItemPrice = (body) => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/cart/price`, 'put', body)
    let { cart, converter } = data
    toast.success(`Success edited price of ${body.item.serialNumber} to ${body.price}`)
    dispatch(getCart(cart))
  } catch (err) {
    toast.error(`There was an error editing the price of ${body.item.serialNumber}`)
    console.error(err)
  }
}

export const creditCart = (body) => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/cart/credit`, 'put', body)
    let { cart } = data
    toast.success(
      `Successfully credited cart with ${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(body.credit)} `
    )
    dispatch(getCart(cart))
  } catch (err) {
    toast.error(`Error crediting cart`)
    console.error(err)
  }
}

export const updatePaymentMethod = (body) => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/cart/payment-method`, 'put', body)
    let { cart } = data
    toast.success(`Successfully updated payment method`)
    dispatch(getCart(cart))
  } catch (err) {
    toast.error(`Error crediting cart`)
    console.error(err)
  }
}

export const abandonCart = (body) => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/cart`, 'delete', body)
    let { cart, converter } = data
    toast.info(`Cart Abandoned`)
    dispatch(getCart(cart))
  } catch (err) {
    toast.error(`There was an error abandoning cart`)
    console.error(err)
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CART:
      return { ...state, data: action.cart }
    case SET_SEARCH:
      return { ...state, search: action.search }
    case SET_MAKE:
      return { ...state, make: action.make }
    default:
      return state
  }
}
